export const licensesConstants = {

    ADD_SUCCESS: 'LICENSES_ADD_SUCCESS',
    ADD_FAILURE: 'LICENSES_ADD_FAILURE',

    GETBYID_SUCCESS: 'LICENSES_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'LICENSES_GETBYID_FAILURE',

    GETALL_SUCCESS: 'LICENSES_GETALL_SUCCESS',
    GETALL_FAILURE: 'LICENSES_GETALL_FAILURE',

    GETAPPINFO_SUCCESS: 'LICENSES_GETAPPINFO_SUCCESS',
    GETAPPINFO_FAILURE: 'LICENSES_GETAPPINFO_FAILURE',

    RESET_STATE: 'RESET_STATE'
};