import {recordsService} from "../services/records.service";
import {recordsConstants} from "../constants/records.constants";


export const recordsActions = {
    create,
    edit,
    delete: _delete,
    saveFormValues,
    search,
    searchLast,
    resetState,
    getAllKeywords,
    getAllDataCategories,
    getAllPossibleApplications,
    getAllPotentialProblematicApplications,
    getAllPersonalInformation,
    getAllAnnotatedClasses,
    getNextRecordsPerUser,
    getFinishedOrUnfinishedRecords,
    getOlderVersions,
    getBySidAndVersion,
    setVsLevel,
    getNextRecordsByProject,
    getNotifications,
    deleteNotification,
    changeRecordOwner
};

function create(record) {
    return dispatch => {
        recordsService.create(record)
            .then(
                response => dispatch(success(response)),
                error => dispatch(failure(error.response.data))
            );
    };

    function success(response) {
        return {type: recordsConstants.RECORD_CREATE_SUCCESS, recordId: response}
    }

    function failure(error) {
        return {type: recordsConstants.RECORD_CREATE_FAILURE, error: error}
    }
}

function saveFormValues(type, formValues) {
    return dispatch => {
        dispatch({type: type, formValues: formValues})
    };
}

function edit(record) {
    return dispatch => {
        recordsService.edit(record)
            .then(
                response => dispatch(success(response)),
                error => dispatch(failure(error.response.data))
            );
    };

    function success(response) {
        localStorage.setItem('code', response);
        return {type: recordsConstants.RECORD_EDIT_SUCCESS, code: response}
    }

    function failure(error) {
        return {type: recordsConstants.RECORD_EDIT_FAILURE, error: error}
    }
}

function search(text, page, rowsPerPage) {
    return dispatch => {
        recordsService.search(text, page, rowsPerPage)
            .then(
                results => dispatch(success(results)),
                error => dispatch(failure(error.response.data))
            );
    };

    function success(results) {
        return {type: recordsConstants.SEARCH_TEXT_SUCCESS, results: results}
    }

    function failure(error) {
        return {type: recordsConstants.SEARCH_TEXT_FAILURE, error: error}
    }
}

function searchLast(text, page, rowsPerPage, recordsWithoutFiles) {
    return dispatch => {
        recordsService.searchLast(text, page, rowsPerPage, recordsWithoutFiles)
            .then(
                results => dispatch(success(results)),
                error => dispatch(failure(error.response.data))
            );
    };

    function success(results) {
        return {type: recordsConstants.SEARCH_LAST_SUCCESS, results: results}
    }

    function failure(error) {
        return {type: recordsConstants.SEARCH_LAST_FAILURE, error: error}
    }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(sid, payload) {
    return dispatch => {
        recordsService.delete(sid, payload)
            .then(
                () => dispatch(success(sid, payload)),
                error => dispatch(failure(sid, error.response.data))
            );
    };

    function success(recordsSid, recordsPayload) {
        return {type: recordsConstants.RECORD_DELETE_SUCCESS, recordsSid, mode: recordsPayload.mode}
    }

    function failure(recordsSid, error) {
        return {type: recordsConstants.RECORD_DELETE_FAILURE, recordsSid, error}
    }
}

function resetState(type) {
    return dispatch => {
        dispatch({type: type})
    };
}

function getAllKeywords() {
    return dispatch => {
        recordsService.getAllKeywords()
            .then(
                keywords => dispatch(success(keywords)),
                error => dispatch(failure(error.response.data))
            );
    };

    function success(keywords) {
        return {type: recordsConstants.KEYWORDS_GETALL_SUCCESS, keywords: keywords}
    }

    function failure(error) {
        return {type: recordsConstants.KEYWORDS_GETALL_FAILURE, error: error}
    }
}

function getAllDataCategories() {
    return dispatch => {
        recordsService.getAllDataCategories()
            .then(
                dataCategories => dispatch(success(dataCategories)),
                error => dispatch(failure(error.response.data))
            );
    };

    function success(dataCategories) {
        return {type: recordsConstants.DATA_CATEGORIES_GETALL_SUCCESS, dataCategories: dataCategories}
    }

    function failure(error) {
        return {type: recordsConstants.DATA_CATEGORIES_GETALL_FAILURE, error: error}
    }
}

function getAllPossibleApplications() {
    return dispatch => {
        recordsService.getAllPossibleApplications()
            .then(
                possibleApplications => dispatch(success(possibleApplications)),
                error => dispatch(failure(error.response.data))
            );
    };

    function success(possibleApplications) {
        return {type: recordsConstants.POSSIBLE_APPLICATIONS_GETALL_SUCCESS, possibleApplications: possibleApplications}
    }

    function failure(error) {
        return {type: recordsConstants.POSSIBLE_APPLICATIONS_GETALL_FAILURE, error: error}
    }
}

function getAllPotentialProblematicApplications() {
    return dispatch => {
        recordsService.getAllPotentialProblematicApplications()
            .then(
                potentialProblematicApplications => dispatch(success(potentialProblematicApplications)),
                error => dispatch(failure(error.response.data))
            );
    };

    function success(potentialProblematicApplications) {
        return {
            type: recordsConstants.POTENTIAL_PROBLEMATIC_APPLICATIONS_GETALL_SUCCESS,
            potentialProblematicApplications: potentialProblematicApplications
        }
    }

    function failure(error) {
        return {type: recordsConstants.POTENTIAL_PROBLEMATIC_APPLICATIONS_GETALL_FAILURE, error: error}
    }
}

function getAllPersonalInformation() {
    return dispatch => {
        recordsService.getAllPersonalInformation()
            .then(
                personalInformation => dispatch(success(personalInformation)),
                error => dispatch(failure(error.response.data))
            );
    };

    function success(personalInformation) {
        return {type: recordsConstants.PERSONAL_INFORMATION_GETALL_SUCCESS, personalInformation: personalInformation}
    }

    function failure(error) {
        return {type: recordsConstants.PERSONAL_INFORMATION_GETALL_FAILURE, error: error}
    }
}

function getAllAnnotatedClasses() {
    return dispatch => {
        recordsService.getAllAnnotatedClasses()
            .then(
                annotatedClasses => dispatch(success(annotatedClasses)),
                error => dispatch(failure(error.response.data))
            );
    };

    function success(annotatedClasses) {
        return {type: recordsConstants.ANNOTATED_CLASSES_GETALL_SUCCESS, annotatedClasses: annotatedClasses}
    }

    function failure(error) {
        return {type: recordsConstants.ANNOTATED_CLASSES_GETALL_FAILURE, error: error}
    }
}

function getNextRecordsPerUser(userId, page, rowsPerPage) {
    return dispatch => {
        recordsService.getNextRecordsPerUser(userId, page, rowsPerPage)
            .then(
                results => dispatch(success(results, page, rowsPerPage)),
                error => dispatch(failure(error.response.data))
            );
    };

    function success(results, usersRecordsPage, usersRecordsRowsPerPage) {
        return {type: recordsConstants.RECORD_GETNEXT_PER_USER_SUCCESS, results: results, usersRecordsPage: usersRecordsPage, usersRecordsRowsPerPage:usersRecordsRowsPerPage}
    }

    function failure(error) {
        return {type: recordsConstants.RECORD_GETNEXT_PER_USER_FAILURE, error: error}
    }
}

function getOlderVersions(sid, page, rowsPerPage) {
    return dispatch => {
        recordsService.getOlderVersions(sid, page, rowsPerPage)
            .then(
                results => dispatch(success(results)),
                error => dispatch(failure(error.response.data))
            );
    };

    function success(results) {
        return {type: recordsConstants.RECORD_GET_OLDER_VERSIONS_SUCCESS, results: results}
    }

    function failure(error) {
        return {type: recordsConstants.RECORD_GET_OLDER_VERSIONS_FAILURE, error: error}
    }
}

function getBySidAndVersion(sid, version) {
    return dispatch => {
        recordsService.getBySidAndVersion(sid, version)
            .then(
                record => dispatch(success(record)),
                error => dispatch(failure(error.response.data))
            );
    };

    function success(record) {
        return {type: recordsConstants.RECORD_GET_BY_SID_AND_VERSION_SUCCESS, record: record}
    }

    function failure(error) {
        return {type: recordsConstants.RECORD_GET_BY_SID_AND_VERSION_FAILURE, error: error}
    }
}

function getFinishedOrUnfinishedRecords(status, page, rowsPerPage) {
    return dispatch => {
        recordsService.getFinishedOrUnfinishedRecords(status, page, rowsPerPage)
            .then(
                results => dispatch(success(results, status, page, rowsPerPage)),
                error => dispatch(failure(error))
            );
    };

    function success(results, recordsStatus, recordUploadsPage, recordUploadsRowsPerPage) {
        return {type: recordsConstants.GET_FINISHED_OR_UNFINISHED_RECORDS_SUCCESS, results: results, status: recordsStatus, recordUploadsPage: page, recordUploadsRowsPerPage: recordUploadsRowsPerPage}
    }

    function failure(error) {
        return {type: recordsConstants.GET_FINISHED_OR_UNFINISHED_RECORDS_FAILURE, error: error}
    }
}

function setVsLevel(vsLevel) {
    return dispatch => {
        dispatch({type: recordsConstants.SET_VS_LEVEL, vsLevelValue: vsLevel})
    };
}

function getNextRecordsByProject(projectId, page, rowsPerPage) {
    return dispatch => {
        recordsService.getNextRecordsByProject(projectId, page, rowsPerPage)
            .then(
                results => dispatch(success(results, page, rowsPerPage)),
                error => dispatch(failure(error))
            );
    };

    function success(results, projectsRecordsPage, projectRecordsRowsPerPage) {
        return {type: recordsConstants.RECORD_GETNEXT_BY_PROJECT_SUCCESS, results: results, projectsRecordsPage: projectsRecordsPage, projectRecordsRowsPerPage: projectRecordsRowsPerPage}
    }

    function failure(error) {
        return {type: recordsConstants.RECORD_GETNEXT_BY_PROJECT_FAILURE, error: error}
    }
}

function getNotifications(page, rowsPerPage) {
    return dispatch => {
        recordsService.getNotifications(page, rowsPerPage)
            .then(
                notifications => dispatch(success(notifications)),
                error => dispatch(failure(error))
            );
    };

    function success(notifications) {
        return {type: recordsConstants.GET_NOTIFICATIONS_SUCCESS, notifications: notifications}
    }

    function failure(error) {
        return {type: recordsConstants.GET_NOTIFICATIONS_FAILURE, error: error}
    }
}

function deleteNotification(id) {
    return dispatch => {
        recordsService.deleteNotification(id)
            .then(
                info => dispatch(success(info)),
                error => dispatch(failure(error))
            );
    };

    function success(info) {
        return {type: recordsConstants.DELETE_NOTIFICATIONS_SUCCESS}
    }

    function failure(error) {
        return {type: recordsConstants.DELETE_NOTIFICATIONS_FAILURE, error: error}
    }
}

function changeRecordOwner(originalOwner, newOwner) {
    return dispatch => {
        recordsService.changeRecordOwner(originalOwner, newOwner)
            .then(
                () => dispatch(success()),
                error => dispatch(failure(error.response.data))
            );
    };
    function success() {
        return {type: recordsConstants.CHANGE_RECORD_OWNER_SUCCESS}
    }

    function failure(error) {
        return {type: recordsConstants.CHANGE_RECORD_OWNER_FAILURE, error: error}
    }
}