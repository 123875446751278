import React, {useEffect, useRef} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {FormattedMessage} from "react-intl";
import Container from "@material-ui/core/Container";
import {usersActions} from "../../actions/users.actions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import Alert from '@material-ui/lab/Alert';
import {object as yupobject, string as yupstring} from "yup";
import {Controller,useForm} from "react-hook-form";
import Box from "@material-ui/core/Box";
import {usersService} from "../../services/users.service";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(20),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function getFormValidationSchema() {
    let validationSchema = {
        username: yupstring()
            .trim().required(<FormattedMessage id="user.error.blank"/>)
            .min(3, <FormattedMessage id="user.error.userNameTooShort"/>)
            .max(32, <FormattedMessage id="user.error.userNameTooLong"/>),
        password: yupstring()
            .required(<FormattedMessage id="user.error.blank"/>)
            .min(8, <FormattedMessage id="user.error.passwordTooShort"/>)
            .max(80, <FormattedMessage id="user.error.passwordTooLong"/>)
    }
    return yupobject().shape(validationSchema);
}

function ErrorMessage({usersState, errorCode }) {
    if (usersState.passwordChanged === true && !errorCode) {
        return (
            <Alert severity="success">
                <FormattedMessage id="password.change.success"/>
            </Alert>
        );
    }

    if (errorCode) {
        const errorMessage = (errorCode === 400 || errorCode === 401) ? "login.error.invalidUsernamePassword" : "login.error.serverError";

        return (
            <Alert severity="error">
                <FormattedMessage id={errorMessage}/>
            </Alert>
        );
    } else {
        return null;
    }
}

export default function LoginPage() {
    const { errors, handleSubmit, control} = useForm({
        validationSchema: getFormValidationSchema(),
        defaultValues: {
            username: '',
            password: ''
        }
    });

    const classes = useStyles();
    const dispatch = useDispatch();
    const authenticationState = useSelector(state => state.authenticationState)
    const usersState = useSelector(state => state.usersState)
    const inputRefUsername = useRef()
    const inputRefPassword = useRef();

    useEffect(() => {
        if(errors.username) {
            inputRefUsername.current.focus();
        } else if(errors.password) {
            inputRefPassword.current.focus();
        }
    },[errors]);

    // reset login status
    useEffect(() => {
        if(usersService.getLoggedInUser()?.token) {
            dispatch(usersActions.logout());
        }
    }, [dispatch]);

    function onSubmit(values) {
        const payload = {
            username: values.username.toLowerCase(),
            password: values.password,
        }

        dispatch(usersActions.login(payload.username.toLowerCase(), payload.password));
    }

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.paper}>
                <Typography component="h1" variant="h5">
                    <FormattedMessage id="user.login" />
                </Typography>
                <form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                    <Controller
                        as={TextField}
                        id="login_username"
                        control={control}
                        name="username"
                        label={<FormattedMessage id="user.username"/>}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        autoFocus
                        error={!!errors.username}
                        helperText={errors.username ? errors.username.message : ''}
                        inputRef={inputRefUsername}
                    />
                    <Controller
                        as={TextField}
                        type="password"
                        control={control}
                        id="login_password"
                        name="password"
                        label={<FormattedMessage id="user.password"/>}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        error={!!errors.password}
                        helperText={errors.password ? errors.password.message : ''}
                        inputRef={inputRefPassword}
                    />
                    <ErrorMessage usersState={usersState} errorCode={authenticationState.errorCode} />
                    <Box mb={1}/>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary">
                        <FormattedMessage id="user.login" />
                    </Button>
                </form>
            </div>
        </Container>
    );
}
