export const organizationsConstants = {

    ADD_SUCCESS: 'ORGANIZATIONS_ADD_SUCESS',
    ADD_FAILURE: 'ORGANIZATIONS_ADD_FAILURE',

    STATE_RESET_REQUESTED: 'RESET_STATE_REQUESTED',

    GETBYID_SUCCESS: 'ORGANIZATIONS_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'ORGANIZATIONS_GETBYID_FAILURE',

    GETALL_SUCCESS: 'ORGANIZATIONS_GETALL_SUCCESS',
    GETALL_FAILURE: 'ORGANIZATIONS_GETALL_FAILURE',

    GETNEXTPAGE_SUCCESS: 'ORGANIZATIONS_GETNEXTPAGE_SUCCESS',
    GETNEXTPAGE_FAILURE: 'ORGANIZATIONS_GETNEXTPAGE_FAILURE',

    UPDATE_SUCCESS: 'ORGANIZATIONS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'ORGANIZATIONS_UPDATE_FAILURE',

    DELETE_SUCCESS: 'ORGANIZATIONS_DELETE_SUCCESS',
    DELETE_FAILURE: 'ORGANIZATIONS_DELETE_FAILURE',

    ENABLED_SUCCESS: 'ORGANIZATIONS_ENABLED_SUCCESS',
    DISABLED_SUCCESS: 'ORGANIZATIONS_DISABLED_SUCCESS',
};