export const recordsConstants = {
  RECORD_CREATE_SUCCESS: "RECORD_CREATE_SUCCESS",
  RECORD_CREATE_FAILURE: "RECORD_CREATE_FAILURE",

  RESET_CREATED_ONLY_SUCCESS: "RESET_CREATED_ONLY_SUCCESS",

  RECORD_DELETE_SUCCESS: "RECORD_DELETE_SUCCESS",
  RECORD_DELETE_FAILURE: "RECORD_DELETE_FAILURE",

  RECORD_EDIT_SUCCESS: "RECORD_EDIT_SUCCESS",
  RECORD_EDIT_FAILURE: "RECORD_EDIT_FAILURE",

  RECORD_GETNEXT_PER_USER_SUCCESS: "RECORD_GETNEXT_PER_USER_SUCCESS",
  RECORD_GETNEXT_PER_USER_FAILURE: "RECORD_GETNEXT_PER_USER_FAILURE",

  RECORD_UPLOAD_FOLDER_DETAILS_SUCCESS: "RECORD_UPLOAD_FOLDER_DETAILS_SUCCESS",
  RECORD_UPLOAD_FOLDER_DETAILS_FAILURE: "RECORD_UPLOAD_FOLDER_DETAILS_FAILURE",

  RECORD_CONTAINS_FILES_SUCCESS: "RECORD_CONTAINS_FILES_SUCCESS",
  RECORD_CONTAINS_FILES_FAILURE: "RECORD_CONTAINS_FILES_FAILURE",

  RECORD_GET_OLDER_VERSIONS_SUCCESS: "RECORD_GET_OLDER_VERSIONS_SUCCESS",
  RECORD_GET_OLDER_VERSIONS_FAILURE: "RECORD_GET_OLDER_VERSIONS_FAILURE",

  RECORD_GET_BY_SID_AND_VERSION_SUCCESS:
    "RECORD_GET_BY_SID_AND_VERSION_SUCCESS",
  RECORD_GET_BY_SID_AND_VERSION_FAILURE:
    "RECORD_GET_BY_SID_AND_VERSION_FAILURE",

  RECORD_GETNEXT_BY_PROJECT_SUCCESS: "RECORD_GETNEXT_BY_PROJECT_SUCCESS",
  RECORD_GETNEXT_BY_PROJECT_FAILURE: "RECORD_GETNEXT_BY_PROJECT_FAILURE",

  SEARCH_TEXT_SUCCESS: "SEARCH_TEXT_SUCCESS",
  SEARCH_TEXT_FAILURE: "SEARCH_TEXT_FAILURE",

  SEARCH_LAST_SUCCESS: "SEARCH_LAST_SUCCESS",
  SEARCH_LAST_FAILURE: "SEARCH_LAST_FAILURE",

  SEARCH_PAYLOAD_SUCCESS: "SEARCH_PAYLOAD_SUCCESS",

  SEARCH_FILTER_CREATION_SUCCESS: "SEARCH_FILTER_CREATION_SUCCESS",

  STATE_RESET_REQUESTED: "RESET_STATE_REQUESTED",
  RESET_SEARCH_FILTER: "RESET_SEARCH_FILTER",
  RESET_RECORD_SEARCH: "RESET_RECORD_SEARCH",
  RESET_RECORD_CREATE: "RESET_RECORD_CREATE",
  RESET_SEARCH_PREVIEW: "RESET_SEARCH_PREVIEW",

  RECORD_CREATE_FILE_SELECTION_STEP_SUCCESS:
    "RECORD_CREATE_FILE_SELECTION_STEP_SUCCESS",
  RECORD_CREATE_BASE_DATA_STEP_SUCCESS: "RECORD_CREATE_BASE_DATA_STEP_SUCCESS",
  RECORD_CREATE_DETAILS_STEP_SUCCESS: "RECORD_CREATE_DETAILS_STEP_SUCCESS",
  RECORD_CREATE_APP_DETAILS_STEP_SUCCESS:
    "RECORD_CREATE_APP_DETAILS_STEP_SUCCESS",
  RECORD_CREATE_DATA_SECURITY_STEP_SUCCESS:
    "RECORD_CREATE_DATA_SECURITY_STEP_SUCCESS",
  RECORD_CREATE_FORM_DATA_SUCCESS: "RECORD_CREATE_FORM_DATA_SUCCESS",
  RECORD_CREATE_FILE_LIST_SUCCESS: "RECORD_CREATE_FILE_LIST_SUCCESS",
  RECORD_CREATE_SHOW_OPTIONAL_FILES_SUCCESS:
    "RECORD_CREATE_SHOW_OPTIONAL_FILES_SUCCESS",

  KEYWORDS_GETALL_SUCCESS: "KEYWORDS_GETALL_SUCCESS",
  KEYWORDS_GETALL_FAILURE: "KEYWORDS_GETALL_FAILURE",

  DATA_CATEGORIES_GETALL_SUCCESS: "DATA_CATEGORIES_GETALL_SUCCESS",
  DATA_CATEGORIES_GETALL_FAILURE: "DATA_CATEGORIES_GETALL_FAILURE",

  POSSIBLE_APPLICATIONS_GETALL_SUCCESS: "POSSIBLE_APPLICATIONS_GETALL_SUCCESS",
  POSSIBLE_APPLICATIONS_GETALL_FAILURE: "POSSIBLE_APPLICATIONS_GETALL_FAILURE",

  POTENTIAL_PROBLEMATIC_APPLICATIONS_GETALL_SUCCESS:
    "POTENTIAL_PROBLEMATIC_APPLICATIONS_GETALL_SUCCESS",
  POTENTIAL_PROBLEMATIC_APPLICATIONS_GETALL_FAILURE:
    "POTENTIAL_PROBLEMATIC_APPLICATIONS_GETALL_FAILURE",

  PERSONAL_INFORMATION_GETALL_SUCCESS: "PERSONAL_INFORMATION_GETALL_SUCCESS",
  PERSONAL_INFORMATION_GETALL_FAILURE: "PERSONAL_INFORMATION_GETALL_FAILURE",

  ANNOTATED_CLASSES_GETALL_SUCCESS: "ANNOTATED_CLASSES_GETALL_SUCCESS",
  ANNOTATED_CLASSES_GETALL_FAILURE: "ANNOTATED_CLASSES_GETALL_FAILURE",

  GET_RECORDS_BY_STATUS_SUCCESS: "GET_RECORDS_BY_STATUS_SUCCESS",
  GET_RECORDS_BY_STATUS_FAILURE: "GET_RECORDS_BY_STATUS_FAILURE",

  GET_FINISHED_OR_UNFINISHED_RECORDS_SUCCESS:
    "GET_FINISHED_OR_UNFINISHED_RECORDS_SUCCESS",
  GET_FINISHED_OR_UNFINISHED_RECORDS_FAILURE:
    "GET_FINISHED_OR_UNFINISHED_RECORDS_FAILURE",

  SET_VS_LEVEL: "SET_VS_LEVEL_SUCCESS",

  PROJECTS_SELECTED: "PROJECTS_SELECTED_SUCCESS",

  UPLOAD_STARTED: "UPLOAD_STARTED_SUCCESS",
  UPLOAD_FINISHED: "UPLOAD_FINISHED_SUCCESS",

  UPPY_FILES_ADDED: "UPPY_FILES_ADDED",
  UPPY_FILES_REMOVED: "UPPY_FILES_REMOVED",

  RECORD_CANCELED: "RECORD_CANCELED",

  RECORD_RESET_BEFORE_UPLOAD: "RECORD_RESET_BEFORE_UPLOAD",

  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  GET_NOTIFICATIONS_FAILURE: "GET_NOTIFICATIONS_FAILURE",

  DELETE_NOTIFICATIONS_SUCCESS: "DELETE_NOTIFICATIONS_SUCCESS",
  DELETE_NOTIFICATIONS_FAILURE: "DELETE_NOTIFICATIONS_FAILURE",

  FILE_COUNT_WARNING: "FILE_COUNT_WARNING",
  FILE_COUNT_WARNING_RESET: "FILE_COUNT_WARNING_RESET",

  FILES_NOT_IN_FOLDER_WARNING: "FILES_NOT_IN_FOLDER_WARNING",
  FILES_NOT_IN_FOLDER_RESET: "FILES_NOT_IN_FOLDER_RESET",

  NETWORK_ERROR: "NETWORK_ERROR",

  CHANGE_RECORD_OWNER_SUCCESS: "CHANGE_RECORD_OWNER_SUCCESS",
  CHANGE_RECORD_OWNER_FAILURE: "CHANGE_RECORD_OWNER_FAILURE",

  FILE_SIZE_TOO_LARGE_WARNING: "FILE_SIZE_TOO_LARGE_WARNING",
  FILE_SIZE_TOO_LARGE_RESET: "FILE_SIZE_TOO_LARGE_RESET",
};
