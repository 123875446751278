import { usersConstants } from '../constants/users.constants';
import {usersService} from "../services/users.service";
import jwtDecode from "jwt-decode";

function getInitialState() {
    const maybeUser = usersService.getLoggedInUser();
    let role
    if(maybeUser) {
        let jwtData = jwtDecode(maybeUser?.token)
        role = jwtData?.role
    }
    return {
        loggedIn: maybeUser != null,
        role: role ? role : "",
        user: maybeUser
    }
}

export function authenticationState(state = getInitialState(), action) {
    switch (action.type) {
        case usersConstants.LOGIN_SUCCESS:
            return {
                loggedIn: true,
                role: action.role,
                user: action.user,
            };
        case usersConstants.LOGIN_FAILURE:
            return {
                errorCode: action.error.response?.status
            };
        case usersConstants.GETLOGGGEDINUSERBYID_SUCCESS:
            return {
                ...state,
                loggedInUser: action.loggedInUser,
            };
        case usersConstants.LOGOUT:
        case usersConstants.GETLOGGGEDINUSERBYID_FAILURE:
            return {};
        default:
            return state
    }
}