import React, {useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import {licensesActions} from "../../actions/licenses.actions";
import {useDispatch, useSelector} from "react-redux";
import {FormattedMessage} from "react-intl";


const useStyles = makeStyles(() => ({
    version: {
        marginLeft: '20px',
        position: 'fixed',
        bottom: 70,
    },
}));

export default function AppInfo() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const version = useSelector(state => state.licensesState?.version?.git);

    useEffect(() => {
        dispatch(licensesActions.getAppInfo());
    }, [dispatch]);

    return (
        <div className={classes.version}>
            <Typography variant="body2" color="textSecondary">
                <FormattedMessage id="data.software.version"/>
                {version?.appVersion}
            </Typography>
        </div>
    );
}