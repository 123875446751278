import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import bdrLogo from "../../assets/images/bdr_logo_transparent.svg";
import Toolbar from "@material-ui/core/Toolbar";
import { FormattedMessage } from "react-intl";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  footer: {
    zIndex: theme.zIndex.drawer + 1,
    width: "100%",
    position: "fixed",
    bottom: 0,
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[200]
        : theme.palette.grey[800],
  },
  copyright: {
    display: "flex",
    marginLeft: "auto",
    alignItems: "baseline",
  },
  privacyStatement: {
    paddingRight: 10,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
    color: "rgba(0, 0, 0, 0.54)",
  },
  logo: {
    paddingTop: "12px",
    paddingBottom: "6px",
  },
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary">
      {"Copyright © BDR "}
      {new Date().getFullYear()}
    </Typography>
  );
}

function Footer() {
  const classes = useStyles();

  function getPrivacyStatement() {
    fetch("/Datenschutzinformationen_DataCube.pdf")
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "Datenschutzinformationen_DataCube.pdf";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }

  function getLicenseInformation() {
    fetch("/Lizenzinformationen.pdf")
      .then((resp) => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = "Lizenzinformationen.pdf";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }

  return (
    <footer className={classes.footer}>
      <Toolbar>
        <div className={classes.logo} edge="start">
          <img src={bdrLogo} alt="BDR Color Logo" />
        </div>
        <section className={classes.copyright}>
          <div
            className={classes.privacyStatement}
            onClick={getLicenseInformation}
          >
            <FormattedMessage id="data.license.information" />
          </div>
          <div
            className={classes.privacyStatement}
            onClick={getPrivacyStatement}
          >
            <FormattedMessage id="data.privacy.statement" />
          </div>
          <Copyright />
        </section>
      </Toolbar>
    </footer>
  );
}

export default React.memo(Footer);
