import {Redirect, Route} from "react-router-dom";
import React from "react";
import NavigationBar from "./NavigationBar";
import {makeStyles} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import {useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        marginBottom:'70px'
    },
}));

export default function PrivateRoute({ component: Component, ...rest }) {
    const classes = useStyles();
    const authenticationState = useSelector(state => state.authenticationState);

    return (
        <>
            { authenticationState?.role !== "FTL" &&
                <NavigationBar {...rest}/>
            }
            <main className={classes.content}>
                <Toolbar />
                    <Route {...rest} render={props => (
                        localStorage.getItem('user')
                            ? <Component {...props} />
                            : <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
                    )}/>
            </main>
        </>
    );
}