import {combineReducers} from 'redux';

import {authenticationState} from './authentication.reducer';
import {usersState} from './users.reducer';
import {alertState} from './alert.reducer';
import {languageState} from './language.reducer';
import {usersConstants} from "../constants/users.constants";
import {projectsState} from "./projects.reducer";
import {organizationsState} from "./organizations.reducer";
import {licensesState} from "./licenses.reducer";
import {recordsState} from "./records.reducer";
import {fileUploadState} from "./fileUpload.reducer";

const rootReducer = combineReducers({
    languageState,
    authenticationState,
    usersState,
    projectsState,
    organizationsState,
    recordsState,
    licensesState,
    alertState,
    fileUploadState
});

const appReducer = (state, action) => {
    if (action.type === usersConstants.LOGOUT) {
        state = undefined;
    }
    return rootReducer(state, action);
}

export default appReducer;