import Axios from "axios";

export const licensesService = {
    add,
    getById,
    getAll,
    getAppInfo
};

function add(license) {
    return Axios.post(`${window.ENVIRONMENT.API_INDEXER}/V1/licenses`, license)
        .then(response => {
            return response.data;
        });
}

function getById(id) {
    return Axios.get(`${window.ENVIRONMENT.API_INDEXER}/V1/licenses/${id}`)
        .then(response => {
            return response.data;
        });
}

function getAll() {
    return Axios.get(`${window.ENVIRONMENT.API_INDEXER}/V1/licenses`)
        .then(response => {
            return response.data;
        });
}

function getAppInfo() {
    return Axios.get(`${window.ENVIRONMENT?.API_USER_MANAGEMENT}/actuator/info`)
        .then(response => {
            return response.data;
        });
}
