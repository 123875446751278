import React, { useEffect } from "react";
import dcLogo from "../../assets/images/zitis-datacube-logo.svg";
import { useDispatch, useSelector } from "react-redux";
import Toolbar from "@material-ui/core/Toolbar";
import { usersActions } from "../../actions/users.actions";
import { history } from "../../helpers/history";
import IconButton from "@material-ui/core/IconButton";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles } from "@material-ui/core/styles";
import FaceIcon from "@material-ui/icons/Face";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Link from "react-router-dom/Link";
import Tooltip from "@material-ui/core/Tooltip";
import { FormattedMessage } from "react-intl";
import Zoom from "@material-ui/core/Zoom";
import { Badge } from "@material-ui/core";
import { recordsActions } from "../../actions/records.actions";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  actions: {
    marginLeft: "auto",
    marginRight: "-12px",
  },
  logo: {
    marginLeft: "-6px",
    marginTop: "20px",
    height: "45px",
    lenght: "240px",
  },
}));

function UserProfile({ isAuthenticated, logout, userId, role }) {
  const recordsState = useSelector((state) => state.recordsState);
  if (isAuthenticated) {
    return (
      <>
        {role !== "FTL" && (
          <Tooltip
            title={<FormattedMessage id="header.hover.profile" />}
            TransitionComponent={Zoom}
          >
            <IconButton
              color="inherit"
              component={Link}
              to={"/users/profile/" + userId}
            >
              <Badge
                badgeContent={recordsState?.numberOfNotifications}
                color="secondary"
              >
                <FaceIcon />
              </Badge>
            </IconButton>
          </Tooltip>
        )}
        <Tooltip
          title={<FormattedMessage id="header.hover.logout" />}
          TransitionComponent={Zoom}
        >
          <IconButton color="inherit" onClick={logout}>
            <ExitToAppIcon />
          </IconButton>
        </Tooltip>
      </>
    );
  } else {
    return null;
  }
}

function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const authenticationState = useSelector((state) => state.authenticationState);
  const userId = authenticationState.user?.id;
  const notifications = useSelector(
    (state) => state.recordsState.notifications
  );

  function logout() {
    dispatch(usersActions.logout());
    history.push("/login");
  }

  useEffect(() => {
    if (
      !notifications &&
      !history.location.pathname.includes("login") &&
      authenticationState.role !== "ADMIN"
    ) {
      dispatch(recordsActions.getNotifications(0, 10));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, authenticationState.role]);

  return (
    <AppBar position="fixed" className={classes.appBar} color={"primary"}>
      <Toolbar>
        {authenticationState.role === "FTL" ? (
          <div
            disabled
            edge="start"
            className={classes.logo}
          >
            <img src={dcLogo} alt="Datacube White Logo" />
          </div>
        ) : (
          <div
            edge="start"
            className={classes.logo}
          >
            <img src={dcLogo} alt="Datacube White Logo" />
          </div>
        )}
        <section className={classes.actions}>
          <UserProfile
            isAuthenticated={authenticationState.loggedIn}
            logout={logout}
            userId={userId}
            role={authenticationState?.role}
          />
        </section>
      </Toolbar>
    </AppBar>
  );
}

export default React.memo(Header);
