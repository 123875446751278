import React, {lazy, Suspense} from 'react';
import {Redirect, Route, Router, Switch} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {IntlProvider} from "react-intl";
import CssBaseline from '@material-ui/core/CssBaseline';
import {createTheme, makeStyles} from '@material-ui/core/styles';
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import LoginPage from "./pages/login/LoginPage";
import {history} from './helpers/history';
import PrivateRoute from "./components/commons/PrivateRoute";
import {messages} from "./i18n";
import Footer from "./components/commons/Footer";
import Header from "./components/commons/Header";
import {AuthProvider} from "./components/commons/AuthProvider";
import '@fontsource/nunito';
require('@formatjs/intl-relativetimeformat/locale-data/de')
require('@formatjs/intl-pluralrules/locale-data/de')

const UserDetailsPage = lazy(() => import( "./pages/user/UserDetailsPage"));
const RecordsCreatePage = lazy(() => import("./pages/record/create/RecordsCreatePage"));
const RecordsCreatePageContinue = lazy(() => import("./pages/record/create/RecordsCreatePage"));
const SearchRecordPage = lazy(() => import("./pages/record/search/SearchRecordPage"));
const UsersListPage = lazy(() => import("./pages/user/UsersListPage"));
const ProjectsDetailsPage = lazy(() => import("./pages/projects/ProjectsDetailsPage"));
const ProjectsManagementPage = lazy(() => import("./pages/projects/ProjectsManagementPage"));
const OrganizationsListPage = lazy(() => import("./pages/organizations/OrganizationsListPage"));
const OrganizationsDetailsPage = lazy(() => import("./pages/organizations/OrganizationsDetailsPage"));
const UserProfilePage = lazy(() => import("./pages/user/UserProfilePage"));
const RecordsEditPage = lazy(() => import('./pages/record/edit/RecordsEditPage'));
const RecordsListPage = lazy(() => import("./pages/record/list/RecordsListPage"));
const RecordUploadsListPage = lazy(() => import("./pages/record/upload/RecordUploadsListPage"));
const RecordUploadsDetailsPage = lazy(() => import("./pages/record/upload/RecordUploadsDetailsPage"));
const FirstLoginPage = lazy(() => import("./pages/login/FirstLoginPage"));

const theme = createTheme({
    root: {
        display: 'flex',
    },
    palette: {
        type: 'light',
        primary: {
            main: '#0070b4'
        },

    },
    typography: {
        fontFamily: [
            "nunito"
        ].join(","),
    }
});

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
    }
}));

export default function App() {

    const classes = useStyles();
    const languageState = useSelector(state => state.languageState)

    return (
        <div className={classes.root}>
            <IntlProvider locale={languageState.languageCode} messages={messages[languageState.languageCode]}>
                <ThemeProvider theme={theme}>
                    <AuthProvider/>
                    <CssBaseline/>
                    <Router history={history}>
                        <>
                            <Header/>
                            <Suspense fallback={<div>Loading...</div>}>
                            <Switch>
                                <PrivateRoute exact path="/" component={SearchRecordPage}/>
                                <PrivateRoute exact path="/firstLogin" component={FirstLoginPage}/>
                                <PrivateRoute exact path="/users" component={UsersListPage}/>
                                <PrivateRoute exact path="/users/create" component={UserDetailsPage}/>
                                <PrivateRoute exact path="/users/edit/:id" component={UserDetailsPage}/>
                                <PrivateRoute exact path="/users/edit/external/:id" component={UserDetailsPage}/>
                                <PrivateRoute exact path="/users/edit/:id/records" component={RecordsListPage}/>
                                <PrivateRoute exact path="/users/profile/:id" component={UserProfilePage}/>
                                <PrivateRoute exact path="/projects/add" component={ProjectsDetailsPage}/>
                                <PrivateRoute exact path="/projects/:id/addUser" component={ProjectsDetailsPage}/>
                                <PrivateRoute exact path="/projects/details/:id" component={ProjectsDetailsPage}/>
                                <PrivateRoute exact path="/projects/details/:id/records" component={RecordsListPage}/>
                                <PrivateRoute exact path="/projects/management" component={ProjectsManagementPage}/>
                                <PrivateRoute exact path="/organizations" component={OrganizationsListPage}/>
                                <PrivateRoute exact path="/organizations/add" component={OrganizationsDetailsPage}/>
                                <PrivateRoute exact path="/organizations/edit/:id" component={OrganizationsDetailsPage}/>
                                <PrivateRoute exact path="/records/create" component={RecordsCreatePage}/>
                                <PrivateRoute exact path="/records/create/continue" component={RecordsCreatePageContinue}/>
                                <PrivateRoute exact path="/records/edit/:sid.:version" component={RecordsEditPage}/>
                                <PrivateRoute exact path="/records/search" component={SearchRecordPage}/>
                                <PrivateRoute exact path="/records/search/upload" component={RecordUploadsListPage}/>
                                <PrivateRoute exact path="/records/upload/:id" component={RecordUploadsDetailsPage}/>
                                <PrivateRoute exact path="/:sid.:version" component={RecordsEditPage}/>
                                <Route exact path="/login" component={LoginPage}/>
                                <Redirect from="*" to="/"/>
                            </Switch>
                            </Suspense>
                        </>
                    </Router>
                    <Footer/>
                </ThemeProvider>
            </IntlProvider>
        </div>
    );
}
