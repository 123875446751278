import { recordsConstants } from "../constants/records.constants";

export function recordsState(state = {}, action) {
  switch (action.type) {
    case recordsConstants.RECORD_CREATE_SUCCESS:
      return {
        ...state,
        created: true,
        recordId: action.recordId,
      };
    case recordsConstants.RECORD_UPLOAD_FOLDER_DETAILS_SUCCESS:
      return {
        ...state,
        uploaded: true,
      };
    case recordsConstants.SEARCH_TEXT_SUCCESS:
      return {
        ...state,
        results: action.results.result,
        numberOfItems: action.results.count,
        edited: false,
        canceled: false,
        deleted: false,
      };
    case recordsConstants.SEARCH_LAST_SUCCESS:
      return {
        ...state,
        results: action.results.result,
        numberOfItems: action.results.count,
        edited: false,
        canceled: false,
        code: null,
        status: null,
        fromProjectsViewRecord: false,
        fromUsersViewRecord: false,
        deleted: false,
      };
    case recordsConstants.RECORD_EDIT_SUCCESS:
      return {
        ...state,
        edited: true,
        code: action.code,
      };
    case recordsConstants.RECORD_DELETE_SUCCESS:
      return {
        ...state,
        results: state.results,
        mode: action.mode,
        deleted: true,
      };
    case recordsConstants.RECORD_GET_BY_SID_AND_VERSION_SUCCESS:
      return {
        ...state,
        item: action.record,
        isEdit: true,
        error: null,
        deleted: false,
      };
    case recordsConstants.RECORD_GETNEXT_PER_USER_SUCCESS:
      return {
        ...state,
        results: action.results.result,
        numberOfItems: action.results.count,
        fromUsersViewRecord: true,
        page: action.usersRecordsPage,
        rowsPerPage: action.usersRecordsRowsPerPage,
        ownerChanged: false,
      };
    case recordsConstants.RECORD_GETNEXT_BY_PROJECT_SUCCESS:
      return {
        ...state,
        results: action.results.result,
        numberOfItems: action.results.count,
        fromProjectsViewRecord: true,
        page: action.projectsRecordsPage,
        rowsPerPage: action.projectRecordsRowsPerPage,
      };
    case recordsConstants.STATE_RESET_REQUESTED:
      return {
        notifications: state.notifications,
      };
    case recordsConstants.SEARCH_FILTER_CREATION_SUCCESS:
      return {
        ...state,
        searchFilterValues: action.formValues,
      };
    case recordsConstants.SEARCH_PAYLOAD_SUCCESS:
      return {
        ...state,
        searchPayloadValues: action.formValues,
        error: null,
      };
    case recordsConstants.RECORD_CONTAINS_FILES_SUCCESS:
      return {
        ...state,
      };
    case recordsConstants.RESET_RECORD_CREATE:
      return {
        ...state,
        projectsSelected: null,
        fileSelectionStepFormValues: null,
        baseDataStepFormValues: null,
        detailsStepFormValues: null,
        dataSecurityStepFormValues: null,
        appDetailsStepFormValues: null,
        vsLevelValue: null,
        created: null,
        recordUploadsTab: false,
      };
    case recordsConstants.RESET_CREATED_ONLY_SUCCESS:
      return {
        ...state,
        created: null,
      };
    case recordsConstants.RESET_RECORD_SEARCH:
      return {
        ...state,
        searchFilterValues: null,
        searchPayloadValues: null,
        results: null,
      };
    case recordsConstants.RESET_SEARCH_FILTER:
      return {
        ...state,
        searchFilterValues: {},
      };
    case recordsConstants.RESET_SEARCH_PREVIEW:
      return {
        ...state,
        item: null,
      };
    case recordsConstants.RECORD_CREATE_FORM_DATA_SUCCESS:
      return {
        ...state,
        formData: action.formValues,
      };
    case recordsConstants.RECORD_CREATE_FILE_SELECTION_STEP_SUCCESS:
      return {
        ...state,
        fileSelectionStepFormValues: action.formValues,
      };
    case recordsConstants.RECORD_CREATE_BASE_DATA_STEP_SUCCESS:
      return {
        ...state,
        baseDataStepFormValues: action.formValues,
      };
    case recordsConstants.RECORD_CREATE_APP_DETAILS_STEP_SUCCESS:
      return {
        ...state,
        appDetailsStepFormValues: action.formValues,
      };
    case recordsConstants.RECORD_CREATE_DETAILS_STEP_SUCCESS:
      return {
        ...state,
        detailsStepFormValues: action.formValues,
      };
    case recordsConstants.RECORD_CREATE_DATA_SECURITY_STEP_SUCCESS:
      return {
        ...state,
        dataSecurityStepFormValues: action.formValues,
        error: null,
      };
    case recordsConstants.RECORD_CREATE_SHOW_OPTIONAL_FILES_SUCCESS:
      return {
        ...state,
        showOptionalFiles: action.formValues,
        error: null,
      };
    case recordsConstants.KEYWORDS_GETALL_SUCCESS:
      return {
        ...state,
        keywords: action.keywords,
      };
    case recordsConstants.DATA_CATEGORIES_GETALL_SUCCESS:
      return {
        ...state,
        dataCategories: action.dataCategories,
      };
    case recordsConstants.POSSIBLE_APPLICATIONS_GETALL_SUCCESS:
      return {
        ...state,
        possibleApplications: action.possibleApplications,
      };
    case recordsConstants.POTENTIAL_PROBLEMATIC_APPLICATIONS_GETALL_SUCCESS:
      return {
        ...state,
        potentialProblematicApplications:
          action.potentialProblematicApplications,
      };
    case recordsConstants.PERSONAL_INFORMATION_GETALL_SUCCESS:
      return {
        ...state,
        personalInformation: action.personalInformation,
      };
    case recordsConstants.ANNOTATED_CLASSES_GETALL_SUCCESS:
      return {
        ...state,
        annotatedClasses: action.annotatedClasses,
      };
    case recordsConstants.GET_RECORDS_BY_STATUS_SUCCESS:
      return {
        ...state,
        results: action.results.result,
        numberOfItems: action.results.count,
        recordUploadsTab: true,
      };
    case recordsConstants.RECORD_GET_OLDER_VERSIONS_SUCCESS:
      return {
        ...state,
        records: action.results.result,
        numberOfRecords: action.results.count,
      };
    case recordsConstants.GET_RECORDS_BY_STATUS_FAILURE:
    case recordsConstants.SEARCH_LAST_FAILURE:
      return {
        ...state,
        error: action.error,
        type: action.type,
        results: null,
      };
    case recordsConstants.GET_FINISHED_OR_UNFINISHED_RECORDS_SUCCESS:
      return {
        ...state,
        results: action.results.result,
        numberOfItems: action.results.count,
        recordUploadsTab: true,
        status: action.status,
        edited: false,
        canceled: false,
        code: null,
        fromUsersViewRecord: false,
        fromProjectsViewRecord: false,
        fileCountWarning: false,
        error: null,
        networkError: false,
        recordUploadsPage: action.recordUploadsPage,
        recordUploadsRowsPerPage: action.recordUploadsRowsPerPage,
        filesNotInFolderWarning: false,
        deleted: false,
        fileSizeTooLargeWarning: false,
      };
    case recordsConstants.SET_VS_LEVEL:
      return {
        ...state,
        vsLevelValue: action.vsLevelValue,
      };
    case recordsConstants.PROJECTS_SELECTED:
      return {
        ...state,
        projectsSelected: true,
      };
    case recordsConstants.UPLOAD_STARTED:
      return {
        ...state,
        uploadStarted: true,
        fileCountWarning: false,
        networkError: false,
        filesNotInFolderWarning: false,
        fileSizeTooLargeWarning: false,
      };
    case recordsConstants.UPLOAD_FINISHED:
      return {
        ...state,
        uploadStarted: false,
        canceled: false,
        isEdit: false,
      };
    case recordsConstants.UPPY_FILES_ADDED:
      return {
        ...state,
        uppyFilesAdded: true,
      };
    case recordsConstants.UPPY_FILES_REMOVED:
      return {
        ...state,
        uppyFilesAdded: false,
      };
    case recordsConstants.RECORD_CANCELED:
      return {
        ...state,
        created: true,
        canceled: true,
        uploadStarted: false,
        uploadFinished: true,
      };
    case recordsConstants.FILES_NOT_IN_FOLDER_WARNING:
      return {
        ...state,
        filesNotInFolderWarning: true,
      };
    case recordsConstants.FILES_NOT_IN_FOLDER_RESET:
      return {
        ...state,
        filesNotInFolderWarning: false,
      };
    case recordsConstants.FILE_SIZE_TOO_LARGE_WARNING:
      return {
        ...state,
        uppyFilesAdded: true,
        fileSizeTooLargeWarning: true,
      };
    case recordsConstants.FILE_SIZE_TOO_LARGE_RESET:
      return {
        ...state,
        fileSizeTooLargeWarning: false,
      };
    case recordsConstants.RECORD_RESET_BEFORE_UPLOAD:
      return {
        ...state,
        created: false,
        edited: false,
        canceled: false,
        code: null,
        uploadStarted: false,
        uppyFilesAdded: null,
        uploadFinished: false,
        filesNotInFolderWarning: false,
        fileSizeTooLargeWarning: false,
      };
    case recordsConstants.GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.notifications.result,
        notificationRemoved: false,
        numberOfNotifications: action.notifications.count,
      };
    case recordsConstants.DELETE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notificationRemoved: true,
      };
    case recordsConstants.FILE_COUNT_WARNING:
      return {
        ...state,
        fileCountWarning: true,
      };
    case recordsConstants.FILE_COUNT_WARNING_RESET:
      return {
        ...state,
        fileCountWarning: false,
      };
    case recordsConstants.NETWORK_ERROR:
      return {
        ...state,
        networkError: true,
      };
    case recordsConstants.CHANGE_RECORD_OWNER_SUCCESS:
      return {
        ...state,
        ownerChanged: true,
      };
    case recordsConstants.RECORD_CREATE_FAILURE:
    case recordsConstants.RECORD_UPLOAD_FOLDER_DETAILS_FAILURE:
    case recordsConstants.RECORD_CONTAINS_FILES_FAILURE:
    case recordsConstants.SEARCH_TEXT_FAILURE:
    case recordsConstants.POSSIBLE_APPLICATIONS_GETALL_FAILURE:
    case recordsConstants.KEYWORDS_GETALL_FAILURE:
    case recordsConstants.DATA_CATEGORIES_GETALL_FAILURE:
    case recordsConstants.POTENTIAL_PROBLEMATIC_APPLICATIONS_GETALL_FAILURE:
    case recordsConstants.PERSONAL_INFORMATION_GETALL_FAILURE:
    case recordsConstants.ANNOTATED_CLASSES_GETALL_FAILURE:
    case recordsConstants.RECORD_DELETE_FAILURE:
    case recordsConstants.RECORD_EDIT_FAILURE:
    case recordsConstants.RECORD_GETNEXT_PER_USER_FAILURE:
    case recordsConstants.RECORD_GET_OLDER_VERSIONS_FAILURE:
    case recordsConstants.RECORD_GET_BY_SID_AND_VERSION_FAILURE:
    case recordsConstants.GET_FINISHED_OR_UNFINISHED_RECORDS_FAILURE:
    case recordsConstants.GET_NOTIFICATIONS_FAILURE:
    case recordsConstants.DELETE_NOTIFICATIONS_FAILURE:
    case recordsConstants.RECORD_GETNEXT_BY_PROJECT_FAILURE:
    case recordsConstants.CHANGE_RECORD_OWNER_FAILURE:
      return {
        ...state,
        error: action.error,
        type: action.type,
      };
    default:
      return state;
  }
}
