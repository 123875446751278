import {organizationsConstants} from "../constants/organizations.constants";

export function organizationsState(state = {}, action) {
    switch (action.type) {
        case organizationsConstants.ADD_SUCCESS:
            return {
                ...state,
                added: true,
                error: false
            };
        case organizationsConstants.GETBYID_SUCCESS:
            return {
                ...state,
                item: action.organization
            };
        case organizationsConstants.GETALL_SUCCESS:
            return {
                items: action.organizations.result,
                updated: false,
                deleted: false,
                numberOfItems: action.organizations.count,
            };
        case organizationsConstants.GETNEXTPAGE_SUCCESS:
            return {
                items: action.organizations.result,
                numberOfItems: action.organizations.count,
                updated: false,
                deleted: false,
                organizationsPage: action.organizationsPage,
                organizationsRowsPerPage: action.organizationsRowsPerPage,
                organizationFilter: action.organizationFilter
            };
        case organizationsConstants.UPDATE_SUCCESS:
            return {
                ...state,
                updated: true,
                error: false
            };
        case organizationsConstants.DELETE_SUCCESS:
            return {
                items: state.items.filter(organization => organization.id !== action.id),
                numberOfItems: state.numberOfItems,
                deleted: true
            };
        case organizationsConstants.ENABLED_SUCCESS:
            return {
                ...state,
                enabled: true
            };
        case organizationsConstants.DISABLED_SUCCESS:
            return {
                ...state,
                enabled: false
            };
        case organizationsConstants.STATE_RESET_REQUESTED:
            return {}
        case organizationsConstants.ADD_FAILURE:
        case organizationsConstants.GETNEXTPAGE_FAILURE:
        case organizationsConstants.GETBYID_FAILURE:
        case organizationsConstants.GETALL_FAILURE:
        case organizationsConstants.UPDATE_FAILURE:
        case organizationsConstants.DELETE_FAILURE:
            return {
                error: action.error,
                type: action.type
            };
        default:
            return state
    }
}