import {fileUploadConstants} from "../constants/fileUpload.constants";

export function fileUploadState(state = {}, action) {
    switch (action.type) {
        case fileUploadConstants.FILE_UPLOAD_SUCCESS:
            return {
                ...state,
                uploaded: true
            };
        case fileUploadConstants.GET_RECORD_FILE_DETAILS_SUCCESS:
            return {
                ...state,
                fileDetails: action.fileDetails,
                deleted: null
            };
        case fileUploadConstants.PUT_FILE_UPLOAD_STATUS_SUCCESS:
            return {
                ...state,
                fileUploadStatus: action.fileUploadStatus
            };
        case fileUploadConstants.RESET_FILE_UPLOAD_STATUS:
            return {
                ...state,
                fileUploadStatus: null
            }
        case fileUploadConstants.FILE_DOWNLOAD_SUCCESS:
            return {
                ...state,
                downloaded: true,
                error: null
            };
        case fileUploadConstants.FILE_DELETE_SUCCESS:
            return {
                ...state,
                deleted: true
            };
        case fileUploadConstants.RESET_ERROR:
            return {
                ...state,
                error: null
            };
        case fileUploadConstants.GET_DOWNLOAD_KEY_SUCCESS:
            return {
                ...state,
                downloadKey: action.downloadKey,
                deleted: null
            };
        case fileUploadConstants.REMOVE_DOWNLOAD_KEY:
            return {
                ...state,
                downloadKey: null
            };
        case fileUploadConstants.STATE_RESET_REQUESTED:
            return {}
        case fileUploadConstants.FILE_UPLOAD_FAILURE:
        case fileUploadConstants.GET_DOWNLOAD_KEY_FAILURE:
        case fileUploadConstants.GET_RECORD_FILE_DETAILS_FAILURE:
        case fileUploadConstants.FILE_DOWNLOAD_FAILURE:
        case fileUploadConstants.PUT_FILE_UPLOAD_STATUS_FAILURE:
            return {
                error: action.error,
                type: action.type
            };
        default:
            return state
    }
}