import { licensesConstants } from "../constants/licenses.constants";
import { licensesService } from "../services/licenses.service";

export const licensesActions = {
  add,
  getById,
  getAll,
  getAppInfo,
  resetState,
};

function add(license) {
  return (dispatch) => {
    licensesService.add(license).then(
      () => {
        dispatch(success());
      },
      (error) => dispatch(failure(error.response.data))
    );
  };

  function success() {
    return { type: licensesConstants.ADD_SUCCESS };
  }

  function failure(error) {
    return { type: licensesConstants.ADD_FAILURE, error: error };
  }
}

function getById(id) {
  return (dispatch) => {
    licensesService.getById(id).then(
      (license) => dispatch(success(license)),
      (error) => dispatch(failure(error.response.data))
    );
  };

  function success(license) {
    return { type: licensesConstants.GETBYID_SUCCESS, license: license };
  }

  function failure(error) {
    return { type: licensesConstants.GETBYID_FAILURE, error: error };
  }
}

function getAll() {
  return (dispatch) => {
    licensesService.getAll().then(
      (licenses) => dispatch(success(licenses)),
      (error) => dispatch(failure(error?.response?.data))
    );
  };

  function success(licenses) {
    return { type: licensesConstants.GETALL_SUCCESS, licenses: licenses };
  }

  function failure(error) {
    return { type: licensesConstants.GETALL_FAILURE, error: error };
  }
}

function getAppInfo() {
  return (dispatch) => {
    licensesService.getAppInfo().then(
      (version) => dispatch(success(version)),
      (error) => dispatch(failure(error.response.data))
    );
  };

  function success(version) {
    return { type: licensesConstants.GETAPPINFO_SUCCESS, version: version };
  }

  function failure(error) {
    return { type: licensesConstants.GETAPPINFO_FAILURE, error: error };
  }
}

function resetState(type) {
  return (dispatch) => {
    dispatch({ type: type });
  };
}
