import {languageConstants} from "../constants/language.constants";
import {languageService} from "../services/langugage.service";

const initialState = {
    languageCode: window.ENVIRONMENT?.LANGUAGE_CODE
};

export function languageState(state = initialState, action) {
    if (action.type === languageConstants.CHANGE_LANGUAGE) {
        languageService.setLanguageCode(action.languageCode);
        return Object.assign({}, state, {
            languageCode: action.languageCode
        });
    }
    return state;
}

