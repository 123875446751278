import {licensesConstants} from "../constants/licenses.constants";

export function licensesState(state = {}, action) {
    switch (action.type) {
        case licensesConstants.ADD_SUCCESS:
            return {
                ...state,
                added: true
            };
        case licensesConstants.GETBYID_SUCCESS:
            return {
                ...state,
                item: action.license
            };
        case licensesConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.licenses
            };
        case licensesConstants.GETAPPINFO_SUCCESS:
            return {
                ...state,
                version: action.version
            };
        case licensesConstants.RESET_STATE:
            return {};
        case licensesConstants.ADD_FAILURE:
        case licensesConstants.GETBYID_FAILURE:
        case licensesConstants.GETALL_FAILURE:
        case licensesConstants.GETAPPINFO_FAILURE:
            return {
                ...state,
                error: action.error,
                type: action.type,
                added: false
            };
        default:
            return state
    }
}