import {projectsConstants} from "../constants/projects.constants";


export function projectsState(state = {}, action) {
    switch (action.type) {
        case projectsConstants.ADD_SUCCESS:
            return {
                ...state,
                added: true,
                error: false
            };
        case projectsConstants.GETBYID_SUCCESS:
            return {
                ...state,
                item: action.project,
                updated: false,
                added: false,
                error: false
            };
        case projectsConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.projects.result,
                updated: false,
                added: false,
                numberOfItems: action.projects.count,
            };
        case projectsConstants.GETNEXTPAGE_SUCCESS:
            return {
                items: action.projects.result,
                numberOfItems: action.projects.count,
                updated: false,
                added: false,
                deleted: false,
                projectsPage: action.projectsPage,
                projectsRowsPerPage: action.projectsRowsPerPage
            }
        case projectsConstants.GETBYORGID_SUCCESS:
            return {
                items: action.projects,
                updated: false,
                added: false
            };
        case projectsConstants.UPDATE_SUCCESS:
            return {
                ...state,
                updated: true,
                error: false
            };
        case projectsConstants.GLOBAL_SUCCESS:
            return {
                ...state,
                global: true
            };
        case projectsConstants.LOCAL_SUCCESS:
            return {
                ...state,
                global: false
            };
        case projectsConstants.DELETE_SUCCESS:
            return {
                items: state.items.filter(project => project.id !== action.id),
                numberOfItems: state.numberOfItems,
                deleted: true
            };
        case projectsConstants.UPDATE_FAILURE:
            return {
                ...state,
                error: action.error,
                type: action.type
            }
        case projectsConstants.STATE_RESET_REQUESTED:
            return {}
        case projectsConstants.ADD_FAILURE:
        case projectsConstants.GETBYID_FAILURE:
        case projectsConstants.GETALL_FAILURE:
        case projectsConstants.GETNEXTPAGE_FAILURE:
        case projectsConstants.DELETE_FAILURE:
            return {
                ...state,
                error: action.error,
                type: action.type,
                updated: false,
                added: false
            };
        default:
            return state
    }
}