export const fileUploadConstants = {
    STATE_RESET_REQUESTED: 'RESET_STATE_REQUESTED',
    RESET_FILE_UPLOAD_STATUS: 'RESET_FILE_UPLOAD_STATUS',

    FILE_UPLOAD_SUCCESS: 'FILE_UPLOAD_SUCCESS',
    FILE_UPLOAD_FAILURE: 'FILE_UPLOAD_FAILURE',

    GET_RECORD_FILE_DETAILS_SUCCESS: 'GET_RECORD_FILE_DETAILS_SUCCESS',
    GET_RECORD_FILE_DETAILS_FAILURE: 'GET_RECORD_FILE_DETAILS_FAILURE',

    PUT_FILE_UPLOAD_STATUS_SUCCESS: 'PUT_FILE_UPLOAD_STATUS_SUCCESS',
    PUT_FILE_UPLOAD_STATUS_FAILURE: 'PUT_FILE_UPLOAD_STATUS_FAILURE',

    FILE_DELETE_SUCCESS: 'FILE_DELETE_SUCCESS',
    FILE_DELETE_FAILURE: 'FILE_DELETE_FAILURE',

    FILE_DOWNLOAD_SUCCESS: 'FILE_DOWNLOAD_SUCCESS',
    FILE_DOWNLOAD_FAILURE: 'FILE_DOWNLOAD_FAILURE',

    RESET_ERROR: 'RESET_ERROR',

    GET_DOWNLOAD_KEY_SUCCESS: 'GET_DOWNLOAD_KEY_SUCCESS',
    GET_DOWNLOAD_KEY_FAILURE: 'GET_DOWNLOAD_KEY_FAILURE',

    REMOVE_DOWNLOAD_KEY: 'REMOVE_DOWNLOAD_KEY',

    UPLOAD_FILES_LIMIT_FOR_WAIT_MESSAGE: 250,

    UPLOAD_MAX_FILE_SIZE: 500000000, // 0.5GB
};