import {usersConstants} from '../constants/users.constants';
import {usersService} from '../services/users.service';
import {alertActions} from './alert.actions';
import {history} from '../helpers/history';
import jwtDecode from 'jwt-decode';

export const usersActions = {
    login,
    logout,
    getAll,
    getAllNoExternal,
    getNextPage,
    getById,
    getLoggedInUserById,
    getUsersRecordCounts,
    update,
    create,
    changePassword,
    resetPassword,
    delete: _delete,
    resetState
};

function login(username, password) {
    return dispatch => {
        usersService.login(username, password)
            .then(
                user => {
                    dispatch(success(user));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function success(user) {
        let role
        if (user) {
            let jwtData = jwtDecode(user?.token)
            role = jwtData?.role
        }
        if(role === "FTL") {
            history.push('/firstLogin');
        } else if(role){
            history.push('/');
        }
        return {type: usersConstants.LOGIN_SUCCESS, user: user, role: role}
    }

    function failure(error) {
        return {type: usersConstants.LOGIN_FAILURE, error: error}
    }
}

function logout() {
    usersService.logout();
    return {type: usersConstants.LOGOUT};
}

function getAll(onlyOrgUsers, page, count, includeInactive, excludeProjectIdAdmins) {
    return dispatch => {
        usersService.getAll(onlyOrgUsers, true, page, count, includeInactive, excludeProjectIdAdmins)
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.response.data))
            );
    };
    function success(users) {
        return {type: usersConstants.GETALL_SUCCESS, users: users}
    }

    function failure(error) {
        return {type: usersConstants.GETALL_FAILURE, error: error}
    }
}

function getAllNoExternal(onlyOrgUsers, page, count) {
    return dispatch => {
        usersService.getAll(onlyOrgUsers, false, page, count, false)
            .then(
                usersNoExternal => dispatch(success(usersNoExternal)),
                error => dispatch(failure(error.response.data))
            );
    };
    function success(usersNoExternal) {
        return {type: usersConstants.GETALL_NO_EXTERNAL_SUCCESS, usersNoExternal: usersNoExternal}
    }

    function failure(error) {
        return {type: usersConstants.GETALL_NO_EXTERNAL_FAILURE, error: error}
    }
}

function getNextPage(page, rowsPerPage, usersFilter, sortBy, order) {
    return dispatch => {
        usersService.getNextPage(page, rowsPerPage, usersFilter, sortBy, order)
            .then(
                users => dispatch(success(users, usersFilter, page, rowsPerPage, sortBy, order)),
                error => dispatch(failure(error.response.data))
            );
    };

    function success(users, usersPageFilter, usersPage, usersRowsPerPage, usersSortBy, usersOrder) {
        return {type: usersConstants.GETNEXTPAGE_SUCCESS, users: users, usersFilter: usersPageFilter, usersPage: usersPage, usersRowsPerPage: rowsPerPage, sortBy: usersSortBy, order: usersOrder}
    }

    function failure(error) {
        return {type: usersConstants.GETNEXTPAGE_FAILURE, error: error}
    }
}

function update(user) {
    return dispatch => {
        usersService.update(user)
            .then(
                () => dispatch(success()),
                error => dispatch(failure(error.response.data))
            );
    };

    function success() {
        return {type: usersConstants.UPDATE_SUCCESS}
    }

    function failure(error) {
        return {type: usersConstants.UPDATE_FAILURE, error: error}
    }
}

function create(user) {
    return dispatch => {
        usersService.create(user)
            .then(
                response => dispatch(success(response)),
                error => dispatch(failure(error.response.data))
            );
    };

    function success(response) {
        return {type: usersConstants.CREATE_SUCCESS, createdUserId: response}
    }

    function failure(error) {
        return {type: usersConstants.CREATE_FAILURE, error: error}
    }
}

function getById(id) {
    return dispatch => {
        usersService.getById(id)
            .then(
                user => dispatch(success(user)),
                error => dispatch(failure(error.response?.data))
            );
    };

    function success(user) {
        return {type: usersConstants.GETBYID_SUCCESS, user: user}
    }

    function failure(error) {
        return {type: usersConstants.GETBYID_FAILURE, error: error}
    }
}

function getLoggedInUserById(id) {
    return dispatch => {
        usersService.getLoggedInUserById(id)
            .then(
                loggedInUser => dispatch(success(loggedInUser)),
                error => dispatch(failure(error.response?.data))
            );
    };

    function success(loggedInUser) {
        return {type: usersConstants.GETLOGGGEDINUSERBYID_SUCCESS, loggedInUser: loggedInUser}
    }

    function failure(error) {
        return {type: usersConstants.GETLOGGGEDINUSERBYID_FAILURE, error: error}
    }
}

function getUsersRecordCounts(userIds) {
    return dispatch => {
        usersService.getUsersRecordCounts(userIds)
            .then(
                recordCounts => dispatch(success(recordCounts)),
                error => dispatch(failure(error.response.data))
            );
    };

    function success(recordCounts) {
        return {type: usersConstants.GET_USERS_RECORD_COUNTS_SUCCESS, recordCounts: recordCounts}
    }

    function failure(error) {
        return {type: usersConstants.GET_USERS_RECORD_COUNTS_FAILURE, error: error}
    }
}

function changePassword(userId, userData, role) {
    return dispatch => {
        usersService.changePassword(userId, userData, role)
            .then(
                () => dispatch(success(role)),
                error => dispatch(failure(error.response.data))
            );
    };

    function success(usersRole) {
        if(usersRole === "FTL") {
            usersService.logout();
            history.push('/')
        }
        return {type: usersConstants.CHANGE_PASSWORD_SUCCESS}
    }

    function failure(error) {
        return {type: usersConstants.CHANGE_PASSWORD_FAILURE, error: error}
    }
}

function resetPassword(id, newPassword) {
    return dispatch => {
        usersService.resetPassword(id, newPassword)
            .then(
                () => dispatch(success()),
                error => dispatch(failure(error.response.data))
            );
    };

    function success() {
        return {type: usersConstants.RESET_PASSWORD_SUCCESS}
    }

    function failure(error) {
        return {type: usersConstants.RESET_PASSWORD_FAILURE, error: error}
    }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        usersService.delete(id)
            .then(
                () => dispatch(success(id)),
                error => dispatch(failure(id, error.response.data))
            );
    };

    function success(usersId) {
        return {type: usersConstants.DELETE_SUCCESS, usersId}
    }

    function failure(usersId, error) {
        return {type: usersConstants.DELETE_FAILURE, usersId, error}
    }
}

function resetState(type) {
    return dispatch => {
        dispatch({type: type})
    };
}

