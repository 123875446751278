import React from "react";
import axios from "axios";
import {usersService} from "../../services/users.service";
import {history} from "../../helpers/history";
import {customAlphabet} from "nanoid";
import jwtDecode from 'jwt-decode';

export function AuthProvider() {
    registerAxiosRequestInterceptor();
    registerAxiosResponseInterceptor();

    return <></>;
}

function registerAxiosRequestInterceptor() {
    axios.interceptors.request.use(
        config => {
            const maybeUser = usersService.getLoggedInUser();

            if (maybeUser && !config.url.includes("token")) {
                config.headers['Authorization'] = 'Bearer ' + maybeUser.token;
            }

            if(!config.headers['Content-Type']) {
                config.headers['Content-Type'] = 'application/json';
            }

            return config;
        },
        error => {
            return Promise.reject(error)
        }
    );
}

function registerAxiosResponseInterceptor() {
    let isRefreshing = false;
    let subscribers = [];
    let temp = 1

    function onRefreshed({ authorisationToken }) {
        subscribers.forEach(cb => cb(authorisationToken));
    }

    function subscribeTokenRefresh(cb) {
        subscribers.push(cb);
    }
    axios.interceptors.response.use((response) => {
        temp = 1
        return response
    }, function (error) {
        const originalRequest = error.config;
        if(error.response.data.code === 1411) {
           retryRefreshToken(error)
        }

        let jwtData

        function responseWithJwtRoleFTL(err, data) {
            return err.response && data?.role === "FTL"
        }

        function responseIcludesRefresh(err, oRequest) {
            return (err.response.status === 401 || err.response.status === 500) && oRequest.url.includes('refresh')
        }

        function responseIncludesToken(err, oRequest) {
            return (err.response.status === 401 || err.response.status === 500) && oRequest.url.includes('/V1/token')
        }

        function responseIncludesUsers(err, oRequest) {
            return err.response.status === 500 && err.response.data.code === 1000 && oRequest.url.includes('/V1/users')
        }

        if(localStorage.getItem("user")){
            jwtData = jwtDecode(localStorage.getItem("user"))
        }
        if(responseWithJwtRoleFTL(error, jwtData)) {
            history.push('firstLogin');
        }
        if (responseIcludesRefresh(error, originalRequest)) {
            history.push('/login');
            window.open("/","_self")
            return Promise.reject(error);
        }

        if (responseIncludesToken(error, originalRequest)) {
            history.push('/login');
            return Promise.reject(error);
        }

        if (responseIncludesUsers(error, originalRequest)) {
            history.push('/login');
            return Promise.reject(error);
        }
        if (error.response.status === 401) {
            userRefreshToken(originalRequest);
            return new Promise(resolve => {
                subscribeTokenRefresh(token => {
                    originalRequest.headers.Authorization = `Bearer ${token}`;
                    resolve(axios(originalRequest));
                });
            });
        }
        return Promise.reject(error);
    });

    function userRefreshToken(originalRequest) {
        if (!isRefreshing && !originalRequest._retry) {
            originalRequest._retry = true
            isRefreshing = true;
            usersService.refreshToken()
                .then(user => {
                    isRefreshing = false;
                    onRefreshed(user.token);
                    subscribers = [];
                })
        }
    }

    function retryRefreshToken (error) {
        if(error.config.retry > temp) {
            temp++
            const nanoid = customAlphabet('1234567890abcdef', 6)
            let originalRequestChanged = JSON.parse(error.config.data)
            originalRequestChanged["sid"] = nanoid()
            error.config.data = originalRequestChanged
            const delayRetryRequest = new Promise((resolve) => {
                setTimeout(() => {
                    resolve();
                },  error.config.retryDelay || 1000);
            });
            return delayRetryRequest.then(() => axios(error.config));
        } else {
            temp = 0
        }
    }
}