import {usersConstants} from "../constants/users.constants";

export function usersState(state = {}, action) {
    switch (action.type) {
        case usersConstants.GETALL_SUCCESS:
            return {
                ...state,
                items: action.users.result,
                updated: false,
                created: false,
                numberOfItems: action.users.count,
            };
        case usersConstants.GETALL_NO_EXTERNAL_SUCCESS:
            return {
                ...state,
                itemsNoExternal: action.usersNoExternal.result,
                numberOfItemsNoExternal: action.usersNoExternal.count,
            };
        case usersConstants.GETNEXTPAGE_SUCCESS:
            return {
                ...state,
                items: action.users.result,
                numberOfItems: action.users.count,
                usersFilter: action.usersFilter,
                updated: false,
                created: false,
                deleted: false,
                usersPage: action.usersPage,
                usersRowsPerPage: action.usersRowsPerPage,
                sortBy: action.sortBy,
                order: action.order
            };
        case usersConstants.GETBYID_SUCCESS:
            return {
                ...state,
                item: action.user,
                updated: false,
                created: false,
                error: null
            };
        case usersConstants.GET_USERS_RECORD_COUNTS_SUCCESS:
            return {
                ...state,
                recordCounts: action.recordCounts,
                item: null
            };
        case usersConstants.GET_PROJECT_USERS_SUCCESS:
            return {
                ...state,
                projectUsers: action.projectUsers,
            };
        case usersConstants.CREATE_SUCCESS:
            return {
                ...state,
                created: true,
                error: false,
                createdUserId: action.createdUserId
            };
        case usersConstants.UPDATE_SUCCESS:
            return {
                ...state,
                updated: true,
                error: false,
                passwordReset: false
            };
        case usersConstants.DELETE_SUCCESS:
            return {
                items: state.items.filter(user => user.id !== action.id),
                numberOfItems: state.numberOfItems,
                deleted: true
            };
        case usersConstants.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                item: state.item,
                passwordChanged: true
            };
        case usersConstants.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordReset: true
            };
        case usersConstants.CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                error: action.error,
                type: action.type,
                updated: false,
                created: false,
                passwordChanged: false
            };
        case usersConstants.STATE_RESET_REQUESTED:
            return {}
        case usersConstants.GETALL_FAILURE:
        case usersConstants.GETNEXTPAGE_FAILURE:
        case usersConstants.GET_USERS_RECORD_COUNTS_FAILURE:
        case usersConstants.GETBYID_FAILURE:
        case usersConstants.UPDATE_FAILURE:
        case usersConstants.CREATE_FAILURE:
        case usersConstants.DELETE_FAILURE:
        case usersConstants.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                error: action.error,
                type: action.type,
                updated: false,
                created: false
            };
        default:
            return state
    }
}