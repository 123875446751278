import Axios from "axios";

export const usersService = {
    login,
    refreshToken,
    getLoggedInUser,
    logout,
    getAll,
    getNextPage,
    getById,
    getLoggedInUserById,
    getUsersRecordCounts,
    update,
    create,
    changePassword,
    resetPassword,
    delete: _delete
};

function login(username, password) {
    let formData = {
        username: username,
        password: password
    }

    return Axios.post(`${window.ENVIRONMENT.API_USER_MANAGEMENT}/V1/token`, JSON.stringify(formData), {
        transformRequest: [(data, headers) => {
            delete headers.common.Authorization
            return data }]})
        .then(response => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(response.data));
            localStorage.setItem('notifications', "notChecked")
            return response.data;
        });
}

function refreshToken() {
    let formData = {
        refresh_token: getLoggedInUser().refresh_token,
    }

    return Axios.post(`${window.ENVIRONMENT.API_USER_MANAGEMENT}/V1/token/refresh`, JSON.stringify(formData),  {
        headers: {
            'Authorization': `Bearer ${getLoggedInUser().refresh_token}`
        }})
        .then(response => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(response.data));
            return response.data;
        });
}


function getLoggedInUser() {
    const maybeUser = localStorage.getItem('user')

    if (maybeUser) {
        return JSON.parse(maybeUser)
    } else {
        return null;
    }
}

function logout() {
    if(getLoggedInUser()?.token) {
        Axios.put(`${window.ENVIRONMENT.API_USER_MANAGEMENT}/V1/token/logout`, {},{
            headers: {
                'Authorization': `Bearer ${getLoggedInUser()?.token}`
            }})
    }
    localStorage.removeItem('user');
    localStorage.removeItem('canceled');
    localStorage.removeItem('notifications');
}

function getAll(onlyOrgUsers, includeExternal, page, count, includeInactive, excludeProjectIdAdmins) {
    let filter

    if(onlyOrgUsers){
        filter = "org_users"
    }
    else{
        filter = "all_users"
    }
    if (page === undefined || page === null) {
        page = 0;
    }
    if (count === undefined || count === null) {
        count = 1000;
    }
    if(includeInactive === undefined || includeInactive === null) {
        includeInactive = false
    }
    if(excludeProjectIdAdmins === undefined || excludeProjectIdAdmins === null) {
        return Axios.get(`${window.ENVIRONMENT.API_USER_MANAGEMENT}/V1/users?filter=${filter}&include_external=${includeExternal}&include_inactive=${includeInactive}&page=${page}&count=${count}`)
            .then(response => {
                return response.data;
            });
    } else {
        return Axios.get(`${window.ENVIRONMENT.API_USER_MANAGEMENT}/V1/users?filter=${filter}&include_external=${includeExternal}&include_inactive=${includeInactive}&page=${page}&count=${count}&exclude_proj_id_admins=${excludeProjectIdAdmins}`)
            .then(response => {
                return response.data;
            });
    }
}

function getNextPage(page, rowsPerPage, filter, sortBy, order) {
    return Axios.get(`${window.ENVIRONMENT.API_USER_MANAGEMENT}/V1/users?filter=${filter}&page=${page}&count=${rowsPerPage}&include_inactive=true&sortBy=${sortBy}&order=${order}`)
        .then(response => {
            return response.data;
        });
}

function getById(id) {
    return Axios.get(`${window.ENVIRONMENT.API_USER_MANAGEMENT}/V1/users/${id}`)
        .then(response => {
            return response.data;
        });
}

function getLoggedInUserById(id) {
    return Axios.get(`${window.ENVIRONMENT?.API_USER_MANAGEMENT}/V1/users/${id}`)
        .then(response => {
            return response.data;
        });
}

function getUsersRecordCounts(userIds) {
    return Axios.post(`${window.ENVIRONMENT.API_INDEXER}/V1/query/userRecordsCount`, userIds)
        .then(response => {
            return response.data;
        });
}

function update(user) {
    return Axios.put(`${window.ENVIRONMENT.API_USER_MANAGEMENT}/V1/users`, user)
        .then(response => {
            return response.data;
        });
}

function create(user) {
    return Axios.post(`${window.ENVIRONMENT.API_USER_MANAGEMENT}/V1/users`, user)
        .then(response => {
            return response.data;
        });
}

function changePassword(id, userData) {
    const userPasswordChangeData = {
        userId: id,
        newPassword: userData.newPassword,
        oldPassword: userData.oldPassword
    }

    return Axios.put(`${window.ENVIRONMENT.API_USER_MANAGEMENT}/V1/users/changePassword`, userPasswordChangeData)
        .then(response => {
            return response.data;
        });
}

function resetPassword(id, newPassword) {
    const userPasswordChangeData = {
        userId: id,
        newPassword: newPassword
    }

    return Axios.put(`${window.ENVIRONMENT.API_USER_MANAGEMENT}/V1/users/resetPassword`,userPasswordChangeData)
        .then(response => {
            return response.data;
        });
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return Axios.delete(`${window.ENVIRONMENT.API_USER_MANAGEMENT}/V1/users/${id}`)
}
