export const projectsConstants = {

    ADD_SUCCESS: 'PROJECTS_ADD_SUCESS',
    ADD_FAILURE: 'PROJECTS_ADD_FAILURE',

    STATE_RESET_REQUESTED: 'RESET_STATE_REQUESTED',

    GETBYID_SUCCESS: 'PROJECTS_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'PROJECTS_GETBYID_FAILURE',

    GETALL_SUCCESS: 'PROJECTS_GETALL_SUCCESS',
    GETALL_FAILURE: 'PROJECTS_GETALL_FAILURE',

    GETNEXTPAGE_SUCCESS: 'PROJECTS_GETNEXTPAGE_SUCCESS',
    GETNEXTPAGE_FAILURE: 'PROJECTS_GETNEXTPAGE_FAILURE',

    GETBYORGID_SUCCESS: 'PROJECTS_GETBYORGID_SUCCESS',
    GETBYORGID_FAILURE: 'PROJECTS_GETBYORGID_FAILURE',

    UPDATE_SUCCESS: 'PROJECTS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'PROJECTS_UPDATE_FAILURE',

    DELETE_SUCCESS: 'PROJECTS_DELETE_SUCCESS',
    DELETE_FAILURE: 'PROJECTS_DELETE_FAILURE',

    GLOBAL_SUCCESS: 'PROJECTS_GLOBAL_SUCCESS',
    LOCAL_SUCCESS: 'PROJECTS_LOCAL_SUCCESS',
};