export const usersConstants = {

    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    GETALL_NO_EXTERNAL_SUCCESS: 'USERS_GETALL_NO_EXTERNAL_SUCCESS',
    GETALL_NO_EXTERNAL_FAILURE: 'USERS_GETALL_NO_EXTERNAL_FAILURE',

    GETNEXTPAGE_SUCCESS: 'USERS_GETNEXTPAGE_SUCCESS',
    GETNEXTPAGE_FAILURE: 'USERS_GETNEXTPAGE_FAILURE',

    GETBYID_SUCCESS: 'USERS_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'USERS_GETBYID_FAILURE',

    GETLOGGGEDINUSERBYID_SUCCESS: 'USERS_GETLOGGGEDINUSERBYID_SUCCESS',
    GETLOGGGEDINUSERBYID_FAILURE: 'USERS_GETLOGGGEDINUSERBYID_FAILURE',

    GET_USERS_RECORD_COUNTS_SUCCESS: 'GET_USERS_RECORD_COUNTS_SUCCESS',
    GET_USERS_RECORD_COUNTS_FAILURE: 'GET_USERS_RECORD_COUNTS_FAILURE',

    GET_PROJECT_USERS_SUCCESS: 'GET_PROJECT_USERS_SUCCESS',
    GET_PROJECT_USERS_FAILURE: 'GET_PROJECT_USERS_FAILURE',

    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    UPDATE_SUCCESS: 'USERS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'USERS_UPDATE_FAILURE',

    CREATE_SUCCESS: 'USERS_CREATE_SUCCESS',
    CREATE_FAILURE: 'USERS_CREATE_FAILURE',

    CHANGE_PASSWORD_SUCCESS: 'USERS_CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'USERS_CHANGE_PASSWORD_FAILURE',

    RESET_PASSWORD_SUCCESS: 'USERS_RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'USERS_RESET_PASSWORD_FAILURE',

    STATE_RESET_REQUESTED: 'RESET_STATE_REQUESTED',
};