import React, {useEffect, useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Link from "react-router-dom/Link";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {usersActions} from "../../actions/users.actions";
import AppInfo from "./AppInfo";
import Box from "@material-ui/core/Box";

const drawerWidth = 240;

const useStyles = makeStyles(() => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerContainer: {
        overflow: 'auto',
        marginBottom: '70px'
    },
}));

function NavigationBar({...rest}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const authenticationState = useSelector(state => state.authenticationState)
    const recordsCreated = useSelector(state => state.recordsState.created);
    const [indexOfSelected, setIndexOfSelected] = useState();
    const baseData = useRef();

    useEffect(() => {
        if (authenticationState?.user?.id !== undefined && authenticationState.role !== "FTL") {
            dispatch(usersActions.getLoggedInUserById(authenticationState.user.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (localStorage.getItem("baseDataStepFormValues")) {
            if (authenticationState.user?.id === localStorage.getItem('userId') && recordsCreated !== true) {
                baseData.current = JSON.parse(localStorage.getItem("baseDataStepFormValues"))
            }
        } else {
            baseData.current = {}
        }
    }, [authenticationState.user, recordsCreated])

    function updateSelected(selectedIndex) {
        setIndexOfSelected(selectedIndex);
    }

    useEffect(() => {
        if(rest.location?.pathname.includes('/profile')){
            setIndexOfSelected("0");
        }
        if ((rest.location?.pathname === "/" || rest.location?.pathname.includes('records/search')) && !rest.location?.pathname.includes('upload')) {
            setIndexOfSelected("1");
        }
        if(rest.location?.pathname.includes('/create') && !rest.location?.pathname.includes('continue')){
            setIndexOfSelected("2");
        }
        if(rest.location?.pathname.includes('/create/continue')){
            setIndexOfSelected("3");
        }
        if(rest.location?.pathname.includes('/search/upload' )){
            setIndexOfSelected("4");
        }
        if(rest.location?.pathname.includes('/users') && !rest.location?.pathname.includes('profile')){
            setIndexOfSelected("5");
        }
        if(rest.location?.pathname.includes('/projects/management')){
            setIndexOfSelected("6");
        }
        if(rest.location?.pathname.includes('/organizations')){
            setIndexOfSelected("7");
        }
    },[rest.location])

    function updateAndRefreshSearchResults(selectedIndex) {
        setIndexOfSelected(selectedIndex);
        window.open("/records/search","_self")
    }

    const optionsByRole = (role) => {
        if (role === "USER" && role !== "FTL") {
            return (
                <>
                    <List>
                        {recordsMenuItems.map((item) => (
                            <ListItem button to={item.url} component={Link} key={item.id}
                                      onClick={() => {
                                          if(item.id === "1") {
                                              updateAndRefreshSearchResults(item.id)
                                          }
                                          updateSelected(item.id)
                                      }}
                                      selected={item.id === indexOfSelected}>
                                <ListItemText primary={item.name}/>
                            </ListItem>
                        ))}
                    </List>
                    <Divider/>
                    {authenticationState?.loggedInUser?.projectAdminIds?.length > 0 &&
                    <ListItem button to={projectMenuItem.url} component={Link} key={projectMenuItem.id}
                              onClick={() => updateSelected(projectMenuItem.id)}
                              selected={projectMenuItem.id === indexOfSelected}>
                        <ListItemText primary={projectMenuItem.name}/>
                    </ListItem>
                    }
                    <Divider/>
                </>
            )
        } else if ((role === "ADMIN" || role === "ORG_ADMIN") && role !== "FTL") {
            return (
                <>
                    <Divider/>
                    <List>
                        {adminsMenuItems.map((item) => (
                            <ListItem button to={item.url} component={Link} key={item.id}
                                      onClick={() => updateSelected(item.id)}
                                      selected={item.id === indexOfSelected}>
                                <ListItemText primary={item.name}/>
                            </ListItem>
                        ))}
                    </List>
                    <Divider/>
                </>
            )
        } else
            return null;
    };

    const adminsMenuItems = [
        {name: <FormattedMessage id="user.users"/>, url: "/users", id: "5"},
        {name: <FormattedMessage id="menu.item.projectManagement"/>, url: "/projects/management", id: "6"},
        ...(authenticationState.role === "ADMIN")
            ? [{name: <FormattedMessage id="menu.item.organizations"/>, url: "/organizations", id: "7"}]
            : []
    ];

    const recordsMenuItems = [
        {name: <FormattedMessage id="menu.item.dataSearch"/>, url: "/records/search", id: "1"},
        {name: <FormattedMessage id="menu.item.dataUpload"/>, url: "/records/create", id: "2"},
        ...(baseData.current?.recordName !== "" && baseData.current?.recordName)
            ? [{name: <FormattedMessage id="menu.item.dataUploadContinue"/>, url: "/records/create/continue", id: "3"}]
            : [],
        {name: <FormattedMessage id="menu.item.dataUploadDetails"/>, url: "/records/search/upload", id: "4"}
    ];

    const searchMenuItem = {name: <FormattedMessage id="menu.item.dataSearch"/>, url: "/records/search", id: "1"};
    const projectMenuItem = {
        name: <FormattedMessage id="menu.item.projectManagement"/>,
        url: "/projects/management",
        id: "6"
    };

    return (
        <Drawer
            className={classes.drawer}
            variant="permanent"
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <Toolbar/>
            <div className={classes.drawerContainer}>
                {authenticationState.role !== "USER" && authenticationState.role !== "FTL" &&
                    <>
                        <Box mt={1}/>
                        <ListItem button to={searchMenuItem.url} component={Link} key={searchMenuItem.id}
                                  onClick={() => updateAndRefreshSearchResults(searchMenuItem.id)}
                                  selected={searchMenuItem.id === indexOfSelected}>
                            <ListItemText primary={searchMenuItem.name}/>
                        </ListItem>
                    </>
                }
                {optionsByRole(authenticationState.role)}
            </div>
            <AppInfo/>
        </Drawer>
    );
}

export default React.memo(NavigationBar);