import Axios from "axios";

export const recordsService = {
    create,
    search,
    searchLast,
    edit,
    delete: _delete,
    getAllKeywords,
    getAllDataCategories,
    getAllPossibleApplications,
    getAllPotentialProblematicApplications,
    getAllPersonalInformation,
    getAllAnnotatedClasses,
    getNextRecordsPerUser,
    getFinishedOrUnfinishedRecords,
    getOlderVersions,
    getBySidAndVersion,
    getNextRecordsByProject,
    getNotifications,
    deleteNotification,
    changeRecordOwner
};

function create(record) {
    return Axios.post(`${window.ENVIRONMENT.API_INDEXER}/V1/records`, record, {retry: 5})
        .then(response => {
            return response.data;
        });
}

function search(content, page, rowsPerPage) {
    return Axios.post(`${window.ENVIRONMENT.API_INDEXER}/V1/query?page=${page}&count=${rowsPerPage}`, content)
        .then(response => {
            return response.data;
        });
}

function searchLast(content, page, rowsPerPage, recordsWithoutFiles) {
    return Axios.get(`${window.ENVIRONMENT.API_INDEXER}/V1/query/last?page=${page}&count=${rowsPerPage}&recordsWithoutFiles=${recordsWithoutFiles}`, content)
        .then(response => {
            return response.data;
        });
}

function edit(record) {
    return Axios.post(`${window.ENVIRONMENT.API_INDEXER}/V1/records`, record)
        .then(response => {
            return response.status;
        });
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(sid, payload) {
    return Axios.put(`${window.ENVIRONMENT.API_INDEXER}/V1/records/${sid}`, payload)
        .then(response => {
            return response.status;
        });
}


function getAllKeywords() {
    return Axios.get(`${window.ENVIRONMENT.API_INDEXER}/V1/idname/1/asc `)
        .then(response => {
            return response.data;
        });

}

function getAllDataCategories() {
    return Axios.get(`${window.ENVIRONMENT.API_INDEXER}/V1/idname/0/asc`)
        .then(response => {
            return response.data;
        });

}

function getAllPossibleApplications() {
    return Axios.get(`${window.ENVIRONMENT.API_INDEXER}/V1/idname/3/asc`)
        .then(response => {
            return response.data;
        });

}

function getAllPotentialProblematicApplications() {
    return Axios.get(`${window.ENVIRONMENT.API_INDEXER}/V1/idname/4/asc`)
        .then(response => {
            return response.data;
        });

}

function getAllPersonalInformation() {
    return Axios.get(`${window.ENVIRONMENT.API_INDEXER}/V1/idname/2/asc`)
        .then(response => {
            return response.data;
        });
}

function getAllAnnotatedClasses() {
    return Axios.get(`${window.ENVIRONMENT.API_INDEXER}/V1/idname/5/asc`)
        .then(response => {
            return response.data;
        });
}


function getNextRecordsPerUser(userId, page, rowsPerPage) {
    return Axios.get(`${window.ENVIRONMENT.API_INDEXER}/V1/query/user/${userId}?page=${page}&count=${rowsPerPage}`)
        .then(response => {
            return response.data;
        });
}

function getFinishedOrUnfinishedRecords(status, page, rowsPerPage) {
    return Axios.get(`${window.ENVIRONMENT.API_INDEXER}/V1/query/byStatus/${status}?page=${page}&count=${rowsPerPage}`)
        .then(response => {
            return response.data;
        });
}

function getOlderVersions(sid, page, rowsPerPage) {
    return Axios.get(`${window.ENVIRONMENT.API_INDEXER}/V1/query/bySid/${sid}?page=${page}&count=${rowsPerPage}`)
        .then(response => {
            return response.data;
        });
}

function getBySidAndVersion(sid, version) {
    return Axios.get(`${window.ENVIRONMENT.API_INDEXER}/V1/records/${sid}?version=${version}`)
        .then(response => {
            return response.data;
        });
}

function getNextRecordsByProject(projectId, page, rowsPerPage) {
    return Axios.get(`${window.ENVIRONMENT.API_INDEXER}/V1/query/byProject/${projectId}?page=${page}&count=${rowsPerPage}`)
        .then(response => {
            return response.data;
        });
}

function getNotifications(page, rowsPerPage) {
    return Axios.get(`${window.ENVIRONMENT?.API_FILE_UPLOAD}/V1/download/notifications?page=${page}&count=${rowsPerPage}`)
        .then(response => {
            return response.data;
        });
}

function deleteNotification(id) {
    return Axios.delete(`${window.ENVIRONMENT.API_FILE_UPLOAD}/V1/download/notifications/${id}`)
        .then(response => {
            return response.status;
        });
}

function changeRecordOwner(originalOwner, newOwner) {
    return Axios.put(`${window.ENVIRONMENT.API_INDEXER}/V1/records/owner/${originalOwner}/${newOwner}`)
        .then(response => {
            return response.status;
        });
}